import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../shared/constants/routes";

interface IAdminDashboard {
}

const AdminDashboard: React.FC<IAdminDashboard> = (props) => {

    const navigate = useNavigate();

    return (
        <div>
            <Button variant="outlined"
            onClick={() => {
                navigate(ROUTES.AUTH_NET_OVERVIEW)
            }}
            >
                AuthNet Overview
            </Button>
        </div>
    );
};

export default AdminDashboard;
