import React from 'react';

// Define the component prop types
interface JsonDisplayProps {
  inputData: any; // Use a more specific type if known, for better type safety
  title?: string; // Optional title prop
}

// The updated JsonDisplay component with title inside the styled box
const JsonDisplay: React.FC<JsonDisplayProps> = ({ inputData, title }) => {
  return (
    <pre
      style={{
        backgroundColor: 'lightgrey',
        color: 'black',
        whiteSpace: 'pre-wrap',
        padding: '10px',
        borderRadius: '5px',
        fontFamily: 'monospace',
        overflowX: 'auto',
        fontSize: '16px', // Example font size, adjust as needed
      }}
    >
      {/* Render the title if it exists, styled differently */}
      {title && <strong style={{ fontSize: '18px' }}>{title}</strong>}
      {/* Add some space between the title and JSON data, only if there's a title */}
      {title && '\n\n'}
      {JSON.stringify(inputData, null, 2)}
    </pre>
  );
};

export default JsonDisplay;
