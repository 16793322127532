import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { checkAuthNet } from "../../../store/actions/adminActions";
import JsonDisplay from "../../../components/shared/JsonDisplay";

interface IAuthNetOverview {
}

const AuthNetOverview: React.FC<IAuthNetOverview> = (props) => {
    const dispatch = useDispatch();
    
    const [authNetSummary, setAuthNetSummary] = useState<any>({})

    useEffect(() => {
        dispatch(checkAuthNet({}, (data) => {
            console.log("check auth net", data)
            if (typeof data === 'string'){
                setAuthNetSummary({
                    data: data
                })
            }
        }))
    }, [])

    return (
        <div>
            <JsonDisplay inputData={authNetSummary} />
        </div>
    );
};

export default AuthNetOverview;
