import { isString } from 'lodash';
import errors from './errors';

export function GetFriendlyError(error: any): string {
	try{
	  if (isString(error)){
		return error as string;
	  }
	}
	catch(e){
	
	}
	return 'Error: Unknown Error'
  }


export const boolToString = (val: any, trueVal: string, falseVal: string) => {
  let boolval = false;
  if (typeof val === 'boolean'){
    boolval = val;
  }
  else{
    if (typeof val === "string"){
      if (val.toLowerCase() === 'true'){
        boolval = true;
      }
    }
  }
  if (boolval === true){
    return trueVal
  }
  return falseVal
}

export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const getUrlParam = (param: string) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(param)
}


export const capitalizeFirstLetter = (str: any) => {
  if (!str) return ""; // Handle empty or null strings safely
  return str.charAt(0).toUpperCase() + str.slice(1);
}
