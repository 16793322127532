import { Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { signIn, signupCreateUser } from "../../../store/actions/signupActions";
import { Box, Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getMyUserData } from "../../../store/actions/userActions";
import { passwordExplation, validPasswordRegEx } from "../../../shared/constants/user.constants";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from 'react-google-recaptcha';
import { useState } from "react";
import { showErrorMessage } from "../../../store/actions/genericAction";
import { useSelector } from "../../../store";

export const SignupRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [captcha, setCaptcha] = useState('');
  const [captchaKey, setCaptchaKey] = useState(0);
  const allState = useSelector((state) => state);
  console.log("allState?.main?.my_user?.language", allState?.main?.my_user?.language)

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string()
      .matches(validPasswordRegEx, passwordExplation)
      .required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Required"),
  });

  const handleCaptchaChange = (value?: string | null) => {
    setCaptcha(value as string);
  };


  const register = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    dispatch(
      signupCreateUser(
        {
          UserName: email,
          Password: password,
          Captcha: captcha,
          Language: allState?.main?.my_user?.language || 'en'
        },
        () => {
          dispatch(
            signIn({ email, password }, () => {
              dispatch(
                getMyUserData({}, () => {
                  navigate("/");
                })
              );
            })
          );
        },
        () => {
          setCaptchaKey(captchaKey + 1);
        },
        "Registration Success",
        true,
        true
      )
    );
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        confirmPassword: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        if (!captcha) {
          dispatch(showErrorMessage("Please complete the captcha"));
        }
        else {
          register(values);
          actions.setSubmitting(false);
        }
      }}
    >
      {({ handleChange, handleBlur, values, touched, errors }) => (
        <Form>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Field
              sx={{ width: "300px" }}
              as={TextField}
              name="email"
              label={t('email')}
              variant="outlined"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email ? errors.email : ""}
            />
            <Field
              sx={{ width: "300px" }}
              as={TextField}
              type="password"
              name="password"
              label={t('password')}
              variant="outlined"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && Boolean(errors.password)}
              helperText={
                touched.password && errors.password ? errors.password : ""
              }
            />
            <Field
              sx={{ width: "300px" }}
              as={TextField}
              type="password"
              name="confirmPassword"
              label={t('confirmPassword')}
              variant="outlined"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.confirmPassword && Boolean(errors.confirmPassword)}
              helperText={
                touched.confirmPassword && errors.confirmPassword
                  ? errors.confirmPassword
                  : ""
              }
            />

            <ReCAPTCHA
              key={captchaKey}
              onChange={(event) => handleCaptchaChange(event)}
              // ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
            />

            <Button
              variant={"outlined"}
              type="submit"
              style={{ padding: 10, marginTop: 20 }}
            >
              {t('register')}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
