import { isArray, isNil } from 'lodash'
import { normalize, schema } from 'normalizr'
import { put } from 'redux-saga/effects'
import { getThemeClasses, getThemeColors } from '../../shared/constants/layout-constants'

export const globalSettingsSchema = new schema.Entity("global_settings");
export const metaSchema = new schema.Entity('meta')
export const resourceSchema = new schema.Entity('resources')
export const roleSchema = new schema.Entity('roles')
export const userRoleSchema = new schema.Entity('user_roles')
export const aspNetUserSchema = new schema.Entity('aspNetUser')
export const patientSchema = new schema.Entity('patients')
export const addressSchema = new schema.Entity('addresses')
export const paymentDataSchema = new schema.Entity('payment_datas')
export const contactSchema = new schema.Entity('contacts')
export const stateSchema = new schema.Entity('states')
export const locationSchema = new schema.Entity('locations')
export const patientPlanSchema = new schema.Entity('patient_plans')
export const dependentPatientPlanSchema = new schema.Entity('dependent_patient_plans')
export const relationshipSchema = new schema.Entity('relationships')
export const dependentSchema = new schema.Entity('dependents')
export const productSchema = new schema.Entity('products')
export const aNETTransactionSchema = new schema.Entity('anet_transactions')
export const positionSchema = new schema.Entity('positions') 

export function autoYields (data: any): any[]{
    const yields = [] as any[]

    const overrideResult = 'overrideResult'
    const customHandle = 'customHandle'

    const pData = {
      global_settings: [globalSettingsSchema],
      contact: contactSchema,
      contacts: [contactSchema],
      meta: metaSchema,
      metas: [metaSchema],
      relationship: relationshipSchema,
      relationships: [relationshipSchema],
      resource: resourceSchema,
      resources: [resourceSchema],
      aspNetUser: aspNetUserSchema,
      asp_net_users: [aspNetUserSchema],
      patient: patientSchema,
      patients: [patientSchema],
      patient_plan: patientPlanSchema,
      patient_plans: [patientPlanSchema],
      position: positionSchema,
      positions: [positionSchema],
      dependent_patient_plan: dependentPatientPlanSchema,
      dependent_patient_plans: [dependentPatientPlanSchema],
      dependent: dependentSchema,
      dependents: [dependentSchema],
      address: addressSchema,
      addresses: [addressSchema],
      payment_data: paymentDataSchema,
      payment_datas: [paymentDataSchema],
      product: productSchema,
      products: [productSchema],
      location: locationSchema,
      locations: [locationSchema],
      state: stateSchema,
      states: [stateSchema],
      aNETTransaction: aNETTransactionSchema,
      aNETTransactions: [aNETTransactionSchema],
      sign_now_link: customHandle,
      my_user: customHandle, // no schema only custom handle
      isEmployee: overrideResult,
      myPosition: overrideResult,

    } as any



    Object.keys(data).forEach((property: string) => {

      if (pData[property]){

        const successType = "REDUCE_" + property + "_SUCCESS";

        switch (pData[property]){
          case overrideResult:
            yields.push(
              put({
                type: successType,
                overrides: [
                  {
                    key: property,
                    value: data[property]
                  }
                ],
              })
            );
            break;
          case customHandle:
            if (isNil(data[property])){
              break;
            }
            switch (property) {
              case 'sign_now_link':
                yields.push(
                  put({
                    type: successType,
                    overrides: [
                     {
                      key: 'sign_now_link',
                      value: data[property]
                     }
                    ],
                    forceStateRefresh: true
                  })
                );
                break;
              case 'my_user':
                localStorage.setItem("user", JSON.stringify(data[property]));
                yields.push(
                  put({
                    type: successType,
                    overrides: [
                     {
                      key: 'my_user',
                      value: data[property]
                     }
                    ],
                    forceStateRefresh: true
                  })
                );
                break;
              case 'global_settings':
                yields.push(
                  put({
                    type: successType,
                    overrides: [
                     {
                      key: 'global_settings',
                      value: data[property]
                     },
                     {
                      key: 'theme',
                      value: {
                        colors: getThemeColors(data[property].theme),
                        classes: getThemeClasses(data[property].theme)
                      }
                     }
                    ],
                  })
                );
                localStorage.setItem(
                  "global_settings",
                  JSON.stringify(data[property])
                );
                break;
            }
            break;
          default:
            if (!isNil(data[property])) {
              yields.push(
                put({
                  type: successType,
                  normalized: normalize(data[property], pData[property]),
                })
              );
            }
            break;
        }
      }
      else if (property.startsWith('deleted_') && data[property]){ // check for deleted keys
        const compareString = property.replace('deleted_', '')
        if (pData[compareString]) {
          let deleteIds = data[property];
          let stateKey = '';
          let idAttribute = '';
          if (!isArray(deleteIds)){
            deleteIds = [deleteIds]
          }
          if (isArray(pData[compareString])){
            stateKey = pData[compareString][0]._key
            idAttribute = pData[compareString][0]._idAttribute
          }
          else{
            stateKey = pData[compareString]._key
            idAttribute = pData[compareString]._idAttribute
          }
          deleteIds = deleteIds.map((obj: any) => obj[idAttribute])
          const removeKeys = [{ entities: stateKey, ids: deleteIds }];
          yields.push(put({ type: 'REDUCE_DELETE_' + property + '_SUCCESS', removeKeys, forceStateRefresh: true}));
        }
      }
    });
    return yields
}