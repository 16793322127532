import { forwardRef, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Box, Button, Card, CardContent, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikProps, getIn, useFormikContext } from 'formik';
import { Delete } from '@mui/icons-material';
import { get } from 'lodash';
import { getMyPatientData } from '../../../../store/hooks/getMyPatientData';
import { useSelector } from '../../../../store';
import { getCurrentGoldProductsArray } from '../../../../store/selectors/main';
import { Gender } from '../../../../components/form/Gender';
import { Relationship } from '../../../../components/form/Relationship';
import { Location } from '../../../../components/form/Location';
import { useDispatch } from 'react-redux';
import { signupUpdateFamilyMembers } from '../../../../store/actions/signupActions';
import { DateField } from '../../../../components/form/DateField';
import { productMemberships } from '../../../../shared/utils/product-utils';
import { useTranslation } from 'react-i18next';
import { validateAndSubmitForm } from '../../../../shared/utils/gen-utils';
import { setClientOptions } from '../../../../store/actions/clientOptionsActions';
import { showErrorMessage } from '../../../../store/actions/genericAction';
import { capitalizeFirstLetter } from '../../../../utils/general';

const childrenIds = [1,6]

const dependentSchema = Yup.object({
  planType: Yup.string(),
  dependentId: Yup.number().optional(),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  birthDate: Yup.date()
  .strict(true)
  .required('Birth date is required')
  .when('relationshipId', (relationshipId, schema) => {
    // Verify if relationshipId is among the childrenIds
    const isChild = childrenIds.includes(Number(relationshipId));
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18); // 18 years ago

    return isChild
      ? schema.min(maxDate, 'Child dependents must be under 18')
      : schema;
  }),
  // .min(new Date(new Date().setFullYear(new Date().getFullYear() - 18)), 'Must be under 18 years old'),
  // birthDate: Yup.date()
  //   .required('Birth date is required')

  gender: Yup.number().required('Gender is required'),
  relationshipId: Yup.number().required('Relationship is required'),
  locationId: Yup.number().required('Location is required'),
});

export type DependendType = Yup.InferType<typeof dependentSchema>;

const validationSchema = Yup.object({
  planType: Yup.string().required('Plan type is required'),
  dependentRows: Yup.array().of(dependentSchema),
});
export type SignupRegisterPlanTypeFormType = Yup.InferType<typeof validationSchema>;

export type SignupRegisterPlanTypeProps = { onSuccess: () => void };

enum PLAN_TYPE_GENERAL {
  INDIVIDUAL = 'individual',
  FAMILY = 'family',
}

const highlightedStyle = {
  backgroundColor: '#7b287c',
  '& .MuiTypography-root': {
    color: '#ffffff',
  },
};

const coreCenteredCardContentStyle = {
  cursor: 'pointer',
  height: '100%', // Set a specific height or keep it responsive based on content
  width: 200,
  // Set a specific height or keep it responsive based on content
};

export const SignupRegisterPlanType = forwardRef<FormikProps<SignupRegisterPlanTypeFormType>, SignupRegisterPlanTypeProps>(({ onSuccess }, ref) => {
  const dispatch = useDispatch();
  const myPatientData = getMyPatientData();
  
  //const [planType, setPlanType] = useState(myPatientData?.dependents?.length > 0 ? PLAN_TYPE_GENERAL.FAMILY : PLAN_TYPE_GENERAL.INDIVIDUAL);
  
  const currentGoldProducts = useSelector(getCurrentGoldProductsArray);
  const memberships = productMemberships(currentGoldProducts);
  const {t} = useTranslation();
  const initialValues = useMemo(
    () => {
      const dependentData = myPatientData.dependents.map((dependent): DependendType => {
        const depContact = myPatientData.dependent_contacts.find((contact) => contact.id === dependent.contactId);
        return {
          dependentId: dependent.id ?? 0,
          firstName: depContact?.firstName ?? '',
          lastName: depContact?.lastName ?? '',
          relationshipId: dependent.relationshipId ?? 0,
          birthDate: new Date(depContact?.birthDate || '2024-01-01'),
          gender: depContact?.gender ?? 0,
          locationId: dependent?.locationId ?? 0,
        };
      })
      const planType = myPatientData?.dependents?.length > 0 ? PLAN_TYPE_GENERAL.FAMILY : PLAN_TYPE_GENERAL.INDIVIDUAL

      return {
        planType,
        dependentRows: dependentData,
      }
    },
    [myPatientData],
  );


  return (
    <Formik
      innerRef={ref}
      initialValues={initialValues ?? {
        planType: PLAN_TYPE_GENERAL.INDIVIDUAL,
        dependentRows: [],
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        return new Promise((resolve) => {
          let depRows = [] as any;
          if (values.planType === PLAN_TYPE_GENERAL.FAMILY) {

            const minBirthDate = new Date();
            minBirthDate.setFullYear(minBirthDate.getFullYear() - 18); // Date 18 years ago from today
          

            depRows = values?.dependentRows?.map((depRow) => ({ ...depRow, dependentId: depRow.dependentId || 0 }));
            // depRows.forEach((depRow: any) => {
            //   if (depRow.relationshipId === 1 || depRow.relationshipId === 6) {
            //     console.log("ITERATING RELATIONSHIP ID 1 OR 6")
            //     const birthDate = new Date(depRow.birthDate);

            //     // Check if birthDate is after the minimum date, meaning they are under 18
            //     if (birthDate > minBirthDate) {
            //       console.log(`Dependent with ID ${depRow.dependentId} is under 18 years old.`);
            //       depRow.birthDate = "Under 18"; // or apply other necessary logic
            //       dispatch(showErrorMessage('Dependent under 18 years old are not allowed'));
            //       return;
            //     }
            //     else{
            //       dispatch(showErrorMessage('Was over 18?'));
            //     }
            //   }
            // })
          }
          console.log("plan type submit called?", values)
          dispatch(
            signupUpdateFamilyMembers(
              { SignupUpdateFamilyMembersDTORows: depRows },
              () => {
                onSuccess?.();
                console.log("resolving with success register plan type")
                resolve({
                  status: 'success',
                })
              },
              (data) => {
                console.log("an error?", data)
              },
              '',
              true,
              true,
            ),
          );
        });

      }}
      enableReinitialize={true}
    >
      {({ values, touched, errors, submitForm,isValid, setTouched, validateForm, setFieldValue}) => {
        return (
          <Form style={{ marginTop: '32px' }}>
            <Grid container justifyContent="center" sx={{ mt: 4 }}>
              <Grid item xs={12} lg={9} sx={{ maxWidth: '75%', width: '100%' }}>
                <TableContainer elevation={0} component={Paper}>
                  <Table aria-label="membership pricing table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Individuals</TableCell>
                        <TableCell align="center">Monthly Membership*</TableCell>
                        <TableCell align="center">Yearly Membership</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {memberships.map((row: any, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="center">
                            ${row.monthly.toFixed(2)} ({t('1st month charge is')} ${row.monthly.toFixed(2) * 2}**)
                          </TableCell>
                          <TableCell align="center">${row.yearly.toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                    sx={{ mt: 2 }}
                    style={{
                      margin: 16,
                      padding: 16,
                      border: '1px solid red',
                      borderRadius: 8,
                    }}
                  >
                    * {t('membershipPricingNote')}
                  </Typography>
                </TableContainer>
              </Grid>
            </Grid>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap', // This will allow the items to wrap on smaller screens
                gap: 2, // This creates a gap between items
                my: 2,
              }}
            >
              <Card
                onClick={() => {
                  setFieldValue('planType', PLAN_TYPE_GENERAL.INDIVIDUAL);
                }}
                elevation={0}
                sx={{
                  ...(values.planType === PLAN_TYPE_GENERAL.INDIVIDUAL && highlightedStyle),
                }}
              >
                <CardContent sx={coreCenteredCardContentStyle}>
                  <Typography variant="h6" align="center" sx={{ width: '100%' }}>
                    {capitalizeFirstLetter(t('individual'))}
                  </Typography>
                </CardContent>
              </Card>

              <Card
                onClick={() => {
                  setFieldValue('planType', PLAN_TYPE_GENERAL.FAMILY);
                }}
                elevation={0}
                sx={{
                  ...(values.planType === PLAN_TYPE_GENERAL.FAMILY && highlightedStyle),
                }}
              >
                <CardContent sx={coreCenteredCardContentStyle}>
                  <Typography variant="h6" align="center" sx={{ width: '100%' }}>
                  {capitalizeFirstLetter(t('family'))}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
            <FieldArray name="dependentRows">
              {({ remove, push }) => (
                <Grid container spacing={2} style={{ display: values.planType === PLAN_TYPE_GENERAL.INDIVIDUAL ? 'none' : 'inherit' }}>
                  {values.dependentRows?.map((_, index) => (
                    <Grid item xs={12} key={index}>
                      <Card elevation={0} sx={{ padding: '8px', backgroundColor: '#f9f9f9', border: '2px solid #ebebeb' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={3}>
                            <Field
                              as={TextField}
                              name={`dependentRows[${index}].firstName`}
                              label={t('firstName')}
                              variant="outlined"
                              error={touched.dependentRows && Boolean(get(errors, `dependentRows[${index}].firstName`))}
                              helperText={<ErrorMessage name={`dependentRows[${index}].firstName`} />}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Field
                              as={TextField}
                              name={`dependentRows[${index}].lastName`}
                              label={t('lastName')}
                              variant="outlined"
                              error={touched.dependentRows && Boolean(get(errors, `dependentRows[${index}].lastName`))}
                              helperText={<ErrorMessage name={`dependentRows[${index}].lastName`} />}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                          <Field
    as={DateField}
    name={`dependentRows[${index}].birthDate`}
    label={t('birthDate')}
    variant="outlined"
    InputLabelProps={{ shrink: true }}
    fullWidth
    error={Boolean(getIn(touched, `dependentRows[${index}].birthDate`) && getIn(errors, `dependentRows[${index}].birthDate`))}
    helperText={
      getIn(touched, `dependentRows[${index}].birthDate`) && getIn(errors, `dependentRows[${index}].birthDate`)
        ? <ErrorMessage name={`dependentRows[${index}].birthDate`} />
        : ''
    }
  />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Field
                              as={Gender}
                              name={`dependentRows[${index}].gender`}
                              label={capitalizeFirstLetter(t('gender'))}
                              error={touched.dependentRows && Boolean(get(errors, `dependentRows[${index}].gender`))}
                              helperText={<ErrorMessage name={`dependentRows[${index}].gender`} />}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Field
                              as={Relationship}
                              name={`dependentRows[${index}].relationshipId`}
                              label={capitalizeFirstLetter(t('relationship'))}
                              error={touched.dependentRows && Boolean(get(errors, `dependentRows[${index}].relationshipId`))}
                              helperText={<ErrorMessage name={`dependentRows[${index}].relationshipId`} />}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Field
                              as={Location}
                              name={`dependentRows[${index}].locationId`}
                              label={capitalizeFirstLetter(t("location"))}
                              error={touched.dependentRows && Boolean(get(errors, `dependentRows[${index}].locationId`))}
                              helperText={<ErrorMessage name={`dependentRows[${index}].locationId`} />}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <IconButton onClick={() => remove(index)} aria-label="delete">
                              <Delete />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Button
                      variant={'outlined'}
                      type="button"
                      onClick={() => push({ firstName: '', lastName: '', birthDate: '', gender: '', relationshipId: '', locationId: myPatientData.patient?.locationId || '' })}
                    >
                      {t('addDependent').toUpperCase()}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </FieldArray>
            <Button 
                  onClick={() => {
                    validateAndSubmitForm(
                      validateForm,
                      setTouched,
                      submitForm,
                      isValid,
                      () => {
                        console.log("it was a success!!!")
                        dispatch(setClientOptions({ signUpStep: 'paymentInfo' }));
                      },
                      (error: any) => {
                        if (errors?.dependentRows){
                          const depRows = errors.dependentRows as any
                          depRows.forEach((error: any) => {
                            if(error?.birthDate === 'Child dependents must be under 18'){
                              dispatch(showErrorMessage('Child dependents must be under 18'));
                            }
                          })
                        }
                          console.log("Form submission failed or has errors", error);
                      }
                    );
                  }}
              style={{ marginTop: '16px' }} variant="contained">
              {t('enterYourPaymentInfo')}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
});
