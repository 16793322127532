import React from "react";
import initializeReduxStorage from "./store";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { createTheme, ThemeProvider } from "@mui/material";
import { COLORS } from "./shared/constants/layout-constants";
import { TranslatorProvider } from "./TranslatorProvider";
import InactivityHandler from "./components/general/InactivityHandler";

const store = initializeReduxStorage();

const Main: React.FC = () => {
	document.body.style.backgroundColor = COLORS.WHITE_BACKGROUND;
	const notistackRef = React.createRef<any>();

	const onClickDismiss = (key: any) => () => {
		notistackRef.current.closeSnackbar(key);
	};

	const theme = createTheme({
		palette: {
			primary: {
				main: COLORS.PRIMARY, // Set your primary color
			  },
			  secondary: {
				main: COLORS.SECONDARY, // Set your secondary color
			  },
		}
	});

	return (
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<SnackbarProvider
					ref={notistackRef}
					maxSnack={3}
					autoHideDuration={3000}
					anchorOrigin={{ horizontal: "right", vertical: "top" }}
					action={(key) => (
						<span></span>
					)}
				>
					<TranslatorProvider>
					<BrowserRouter>
					<InactivityHandler/>
						<App />
					</BrowserRouter>
					</TranslatorProvider>
				</SnackbarProvider>
			</ThemeProvider>
		</Provider>
	);
};

export default Main;
