export const ROUTES = {
    'LANDING': '/',
    'SIGNUP': '/signup',
    'REGISTER': '/signup/register',
    'ADMIN_DASHBOARD': '/admin/dashboard',
    'AUTH_NET_OVERVIEW': '/admin/authnet/overview'
}

export const publicRoutes = [
    ROUTES.LANDING,
    ROUTES.SIGNUP
]